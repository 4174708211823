<template>
  <div class="search-input d-flex align-items-center">
    <inline-svg height="12px" class="position-absolute icon ml-1" src="/icons/search-bar-mag.svg" />
    <ion-input v-model="search.text" class="c-input" @click.stop @keyup.enter="handleSearch" />
  </div>
</template>

<script lang="ts" setup>
const props = defineProps({
  clearValue: { type: Boolean, default: true },
  defaultValue: { type: String, default: '' },
  noOpen: { type: Boolean, default: false },
  auto: { type: Boolean, default: false },
});

const clearValue = toRef(props, 'clearValue');
const defaultValue = toRef(props, 'defaultValue');
const noOpen = toRef(props, 'noOpen');
const auto = toRef(props, 'auto');

const emits = defineEmits(['search']);

const handleSearch = debounce(() => {
  emits('search', search.value.text);

  if (noOpen.value) return;

  openSearchPage();
}, 500);

const { search, openSearchPage } = useSearch(clearValue.value, defaultValue);

watch(
  search,
  () => {
    if (auto.value) handleSearch();
  },
  { deep: true }
);
</script>

<style lang="sass" scoped>
.search-input
  height: 24px
  width: 100%
  .icon
    z-index: 111
  .c-input
    height: 24px !important
    min-height: unset !important
    background: transparent !important
    ::v-deep
      input
        height: 24px !important
        min-height: unset !important
        background: #FFF
        padding: 0 5px 0 20px !important
</style>
