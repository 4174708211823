export const useSearch = (clearValue = false, defaultValue = ref('')) => {
  const search = ref({ text: defaultValue.value });
  const isSearching = ref(false);

  watch(defaultValue, () => {
    search.value.text = defaultValue.value;
  });

  const openSearchPage = () => {
    const router = useRouter();
    isSearching.value = false;
    router.replace({
      name: 'search',
      query: { term: search.value.text, tab: 'all' },
    });

    if (clearValue) {
      search.value.text = '';
    }
  };

  return { search, isSearching, openSearchPage };
};
